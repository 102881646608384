import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import { Link as LinkUnited } from '@olist/united';

export const Popup = styled.section`
  background: white;
  border-radius: 15px;
  box-shadow: 1px 1px 1px 1px #cccccc;
  height: auto;
  left: 50%;
  max-height: 80%;
  max-width: 80%;
  overflow: auto;
  padding: 40px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  z-index: 99;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.large}) {
          padding: 5%;
        }
      `
    );
  }}
  .top {
    align-items: center;
    display: flex;
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.large}) {
            flex-direction: column;
            align-items: flex-start;
          }
        `
      );
    }}
    img {
      object-fit: contain;
      margin-right: 20px;
      object-position: left;
      ${({ theme }) => {
        return (
          theme &&
          css`
            @media (max-width: ${theme.breakpoints.large}) {
              margin-bottom: 20px;
            }
          `
        );
      }}
    }
    .shortDescription {
      width: calc(100% - 120px);
      ${({ theme }) => {
        return (
          theme &&
          css`
            @media (max-width: ${theme.breakpoints.large}) {
              width: 100%;
            }
          `
        );
      }}
      h3 {
        font: bold 30px/40px 'Montserrat', sans-serif;
      }
      p {
        font: 14px/24px 'Montserrat', sans-serif;
      }
      a {
        font: bold 14px/24px 'Montserrat', sans-serif;
        position: relative;
        color: black;
        text-decoration: none;
        &::after {
          background: #ff6d00;
          bottom: -4px;
          content: '';
          height: 2px;
          left: 0;
          position: absolute;
          transform: scaleX(0);
          transition: 300ms;
          width: 100%;
        }
        &:hover::after {
          transform: scaleX(1);
        }
      }
    }
  }
  .content {
    align-items: flex-start;
    display: flex;
    margin-top: 40px;
    > div {
      width: 100%;
      p {
        font: 14px/24px 'Montserrat', sans-serif;
        margin-bottom: 10px;

        strong {
          font-weight: 700;
        }
        i {
          font-style: italic;
        }
      }
      ul {
        margin-bottom: 10px;
        li {
          list-style: disc;
          list-style-position: inside;
          font: 14px/24px 'Montserrat', sans-serif;
          p {
            display: inline;
          }
        }
      }
      strong {
        display: inline-block;
        margin-top: 20px;
      }
      table {
        border-collapse: collapse;
        margin-bottom: 20px;
        max-width: 100%;
        th,
        td {
          border: 1px solid #ff6d00;
          font-size: 12px;
          padding: 10px 6px;
          text-align: center;
          width: 25%;
        }
        th {
          background: #ff6d00;
          color: white;
          font-weight: normal;
        }
        td {
          font-size: 20px;
        }
      }
    }
    .swiper-button-next,
    .swiper-button-prev {
      background: transparent;
      box-shadow: none;
    }
    .swiper-button-next {
      right: 15px;
    }
    .swiper-button-prev {
      left: 15px;
    }
  }
  .bottom {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.large}) {
            flex-direction: column;
            > div {
              width: 100%;
              &:last-child {
                text-align: right;
              }
            }
          }
        `
      );
    }}
    span {
      display: block;
      font: bold 14px/24px 'Montserrat', sans-serif;
      margin-bottom: 10px;
    }
    strong {
      border: 1px solid #b5b5b5;
      border-color: #b5b5b5;
      border-radius: 24px;
      color: black;
      display: inline-block;
      font-size: 14px;
      margin-right: 5px;
      padding: 5px 10px;
      transition: 0.3s;
    }
  }
`;

export const Link = styled(LinkUnited)`
  background-color: #f36607;
  border: 1px solid #f36607;
  border-radius: ${themeGet('olist.borderRadius.pill')};
  color: #ffffff;
  height: 48px;
  margin-left: 12px;
  margin-top: 32px;
  padding: 0px 24px;
  width: fit-content;

  &:hover {
    background-color: #f36607 !important;
    border: 1px solid #f36607 !important;
    box-shadow: none !important;
    color: #ffffff !important;
    svg {
      margin-left: 10px;
      width: 12px;
    }
  }
  svg {
    display: block;
    margin-left: 0px;
    overflow: hidden;
    transition: 0.3s;
    width: 0;
    path {
      fill: #ffffff !important;
    }
  }
  a {
    color: #ffffff;
    text-decoration: none;
  }
`;
