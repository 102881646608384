/* eslint-disable react/destructuring-assignment */
import React from 'react';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { Text } from '@olist/united';

import { Partner } from '../NewPartners/NewPartners.types';
import * as S from './Popup.style';

type PopupProps = {
  partnerSelected: Partner;
};

export const Popup = ({ partnerSelected }: PopupProps) => {
  return (
    <>
      <S.Popup>
        <div className="top">
          <img
            src={partnerSelected.image.url}
            alt={partnerSelected.title}
            width={200}
            height={40}
          />
          <div className="shortDescription">
            <Text.H3>{partnerSelected.title}</Text.H3>
            <p>{partnerSelected.subtitle}</p>
            {partnerSelected.url && (
              <a href={partnerSelected.url} target="_blank" rel="noreferrer">
                {partnerSelected.url}
              </a>
            )}
          </div>
        </div>
        <div className="content">
          <div className="text">{documentToReactComponents(partnerSelected.description?.json)}</div>
        </div>
        <div className="bottom">
          <div className="left">
            {partnerSelected.services && partnerSelected.services.length > 0 && (
              <>
                <span>Serviços</span>
                <div>
                  {partnerSelected.services.map((service) => {
                    return <strong key={service}>{service}</strong>;
                  })}
                </div>
              </>
            )}
          </div>
          <div className="right">
            {partnerSelected.url && (
              <S.Link href={partnerSelected.url} target="_blank" rel="noreferrer">
                Saiba mais
              </S.Link>
            )}
          </div>
        </div>
      </S.Popup>
    </>
  );
};
