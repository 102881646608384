import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import { Flex, Link as LinkUnited } from '@olist/united';

export const DesktopText = styled.span`
  padding-left: 5px;
`;

export const MobileText = styled.span`
  display: none;
  padding-left: 5px;
`;

export const Header = styled.header`
  background-color: ${themeGet('olist.color.neutral.softest')};
  border-bottom: 1px solid ${themeGet('olist.color.neutral.softer')} !important;
  box-shadow: ${themeGet('olist.shadow.low')};
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 90;
`;

export const Container = styled(Flex)`
  align-items: center;
  height: 80px;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1328px;
  padding: 0 ${themeGet('olist.spacing.stack.medium')};
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.large}) {
          margin: auto ${themeGet('olist.spacing.stack.large')};
        }

        @media (max-width: ${theme.breakpoints.small}) {
          margin: auto ${themeGet('olist.spacing.stack.1xsmall')};
        }
      `
    );
  }}
  > div {
    justify-content: space-between;
    width: 100%;
    > div {
      &:nth-child(2) {
        ${({ theme }) => {
          return (
            theme &&
            css`
              @media (max-width: ${theme.breakpoints.small}) {
                display: none;
              }
            `
          );
        }}
      }
    }
  }
`;

export const Link = styled(LinkUnited)`
  background-color: ${themeGet('olist.color.primary.base')};
  border-radius: ${themeGet('olist.borderRadius.pill')};
  box-shadow: ${themeGet('olist.shadow.low')};
  color: ${themeGet('olist.color.neutral.softest')};
  padding: 0 ${themeGet('olist.spacing.inset.large')};
  &:hover {
    a {
      color: ${themeGet('olist.color.primary.base')};
    }
  }
  a {
    color: ${themeGet('olist.color.neutral.softest')};
    text-decoration: none;
  }
`;
