import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import { Flex } from '@olist/united';

export const NewPartners = styled.section`
  margin: 0 auto;
  max-width: 1328px;
  padding: 100px ${themeGet('olist.spacing.stack.medium')};
  &.page {
    padding: 50px ${themeGet('olist.spacing.stack.medium')};
    &:nth-child(even) {
      background: ${themeGet('olist.color.primary.softer')};
    }
  }
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.large}) {
          margin: 100px ${themeGet('olist.spacing.stack.large')};
        }

        @media (max-width: ${theme.breakpoints.small}) {
          margin: 100px ${themeGet('olist.spacing.stack.1xsmall')};
        }
      `
    );
  }}
  > a {
    border-radius: ${themeGet('olist.borderRadius.pill')};
    box-shadow: ${themeGet('olist.shadow.low')};
    display: flex;
    margin: 0 auto;
    padding: 0 ${themeGet('olist.spacing.inset.large')};
    width: fit-content;
    &:hover {
      a {
        color: ${themeGet('olist.color.primary.base')};
      }
    }
    a {
      color: ${themeGet('olist.color.neutral.stronger')};
      text-decoration: none;
    }
  }
`;

export const Content = styled(Flex)`
  flex-wrap: wrap;
  gap: ${themeGet('olist.spacing.stack.medium')};
  justify-content: center;
  margin: ${themeGet('olist.spacing.stack.large')} 0;
  > div {
    border: 1px solid ${themeGet('olist.color.neutral.softer')} !important;
    box-shadow: ${themeGet('olist.shadow.low')};
    padding: ${themeGet('olist.spacing.stack.small')};
    width: calc(20% - ${themeGet('olist.spacing.stack.medium')} + 4px);
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.small}) {
            width: calc(50% - ${themeGet('olist.spacing.stack.medium')} + 12px);
          }
        `
      );
    }}
    img {
      object-fit: contain;
      object-position: center center;
      margin: 10px auto;
    }
    > div:first-child {
      align-items: center;
      border-bottom: 0;
      display: flex;
      gap: ${themeGet('olist.spacing.stack.small')};
      padding: 0;
    }
  }
`;

export const Overlay = styled.div`
  background: black;
  height: 100%;
  left: 0;
  opacity: 0.3;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
`;
