import React from 'react';

import NextLink from 'next/link';

import { Card, Link, Text } from '@olist/united';

import { Image } from '@common/components/Image';

import { Popup } from '../Popup';
import * as S from './NewPartners.style';
import * as T from './NewPartners.types';

type NewPartnersProps = {
  items: T.Partner[];
  hasLink: boolean;
  extraClass?: string;
  key?: string;
};

export const NewPartners = ({ extraClass, hasLink, items, key }: NewPartnersProps) => {
  const [partnerSelected, setPartnerSelected] = React.useState<T.Partner>();
  return (
    <S.NewPartners id="partners" key={key} className={extraClass}>
      {items[0]?.category && <Text.H3>{items[0].category.title}</Text.H3>}
      <S.Content>
        {items.length > 0 &&
          items.map((item) => {
            return (
              <Card
                key={item.title}
                className={item.isTopPerformer ? 'top-performer' : ''}
                onClick={() => {
                  setPartnerSelected(item);
                }}
              >
                <Card.Header>
                  <Image src={item.image.url} alt={item.title} width="100%" height="40px" />
                </Card.Header>
              </Card>
            );
          })}
      </S.Content>
      {hasLink && (
        <Link href="/parceiros">
          <NextLink href="/parceiros">ver todos os parceiros</NextLink>
        </Link>
      )}
      {partnerSelected && (
        <>
          <S.Overlay onClick={() => setPartnerSelected(undefined)} />
          <Popup partnerSelected={partnerSelected} />
        </>
      )}
    </S.NewPartners>
  );
};
