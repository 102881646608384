import React from 'react';

import { LayoutProps, SpaceProps } from 'styled-system';

import * as S from './Image.styles';

export type ImageProps = S.ImageProps &
  Partial<LayoutProps> &
  SpaceProps & {
    alt: string;
    src: string;
  };

export const Image: React.FC<ImageProps> = ({ alt, src, ...props }) => (
  // @ts-ignore
  <S.Image src={src} alt={alt} {...props} />
);
