import React from 'react';

import NextLink from 'next/link';

import { Nav, Navbar } from '@olist/united';

import { Image } from '@common/components/Image';

import * as S from './Header.style';

export const Header: React.FC = () => {
  return (
    <S.Header>
      <S.Container>
        <Navbar>
          <Navbar.Brand>
            <NextLink href="/" passHref>
              <a href="replace">
                <Image src="/images/vnda-network.svg" alt="logo da vnda" height="24px" />
              </a>
            </NextLink>
          </Navbar.Brand>
          <Nav>
            <Nav.Link
              to="/program"
              onClick={() => {
                const program = document.getElementById('program');
                program
                  ? program.scrollIntoView({ behavior: 'smooth', block: 'center' })
                  : (window.location.href = '/#program');
              }}
            >
              network program
            </Nav.Link>
            <Nav.Link
              to="/partners"
              onClick={() => {
                const partners = document.getElementById('partners');
                partners
                  ? partners.scrollIntoView({ behavior: 'smooth', block: 'center' })
                  : (window.location.href = '/#partners');
              }}
            >
              parceiros
            </Nav.Link>
            <Nav.Link
              to="/benefits"
              onClick={() => {
                const benefits = document.getElementById('benefits');
                benefits
                  ? benefits.scrollIntoView({ behavior: 'smooth', block: 'center' })
                  : (window.location.href = '/#benefits');
              }}
            >
              benefícios
            </Nav.Link>
            <Nav.Link
              to="/strategy"
              onClick={() => {
                const strategy = document.getElementById('strategy');
                strategy
                  ? strategy.scrollIntoView({ behavior: 'smooth', block: 'center' })
                  : (window.location.href = '/#strategy');
              }}
            >
              estratégia
            </Nav.Link>
            <Nav.Link
              to="/contact"
              onClick={() => {
                const contact = document.getElementById('contact');
                contact
                  ? contact.scrollIntoView({ behavior: 'smooth', block: 'center' })
                  : (window.location.href = '/#contact');
              }}
            >
              contato
            </Nav.Link>
          </Nav>
          <S.Link>
            <NextLink href="/auth/signin">login</NextLink>
          </S.Link>
        </Navbar>
      </S.Container>
    </S.Header>
  );
};
