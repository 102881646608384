import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

export const DesktopText = styled.span`
  padding-left: 5px;
`;

export const MobileText = styled.span`
  display: none;
  padding-left: 5px;
`;

export const Footer = styled.footer`
  width: 100%;
  z-index: 1;
`;

export const Container = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1328px;
  padding: 0 ${themeGet('olist.spacing.stack.medium')};
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.large}) {
          margin: auto ${themeGet('olist.spacing.stack.large')};
        }

        @media (max-width: ${theme.breakpoints.small}) {
          margin: auto ${themeGet('olist.spacing.stack.1xsmall')};
        }
      `
    );
  }}
  > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    &:first-child {
      border-bottom: 1px solid ${themeGet('olist.color.neutral.softer')};
      > div {
        width: 100%;
        justify-content: space-between;
        ${({ theme }) => {
          return (
            theme &&
            css`
              @media (max-width: ${theme.breakpoints.small}) {
                align-items: baseline;
                margin-right: 0;
                a {
                  margin-right: 0 !important;
                }
              }
            `
          );
        }}
        &:last-child {
          a {
            align-items: center;
            display: flex;
            fill: ${themeGet('olist.color.primary.base')};
            height: 35px;
            margin-right: 15px;
            transition: 0.3s;
            &:hover {
              fill: ${themeGet('olist.color.primary.soft')};
              transform: scale(1.2);
            }
          }
        }
      }
    }
    &:last-child {
      ${({ theme }) => {
        return (
          theme &&
          css`
            @media (max-width: ${theme.breakpoints.small}) {
              display: none;
            }
          `
        );
      }}
    }
    a {
      padding-left: 0;
      padding-right: 20px;
    }
  }
`;
